@import "styles/main/theme"
@import "styles/main/colorPalette"

$base-width: 80px
$expand-width: 200px
.sidebar-toggle-button
  @include theme-aware("background-color", "background-secondary", 0.8)
  @include theme-aware('color', 'text-primary')
  @apply absolute text-2xl w-16 h-16 z-40
  @apply duration-200
  filter: grayscale(80%) opacity(1)

  &:hover, &:focus
    @include theme-aware("background-color", "background-secondary", 1)
    @apply outline-none
    filter: grayscale(0%) opacity(1)


.sidebar-overlay
  @apply absolute top-0 bottom-0 left-0 right-0
  @apply z-30 pointer-events-none opacity-0
  @apply duration-300 ease-in-out
  @screen lg
    @apply hidden

  &-show
    @apply bg-black opacity-25
    @apply pointer-events-auto


.sidebar
  @apply overflow-y-auto overflow-x-hidden
  @apply h-screen absolute top-0 z-50
  @apply flex flex-col items-center
  @apply duration-200 shadow-lg
  @include theme-aware("background-color", "background-primary")
  margin-left: -$base-width
  width: $base-width
  @screen lg
    @apply relative
    margin-left: 0

  &-open
    @apply transform translate-x-0
    width: $expand-width
    margin-left: 0

  & > .logo
    @apply flex justify-center items-center
    @apply w-full duration-200
    @apply p-4 mb-4
    height: $base-width

    & > img
      @apply h-full w-full duration-200 my-auto
      @apply object-contain rounded-full

    &-open
      @apply h-32
      & > img
        @apply h-24 w-24


  & > .item-container
    @apply flex flex-col w-full
    flex: 5
  & > .bottom-item-container
    @apply w-full
    @apply mt-auto

  & > .item-container, & > .bottom-item-container
    & > .item
      @include theme-aware("color", "text-primary")
      @apply flex flex-row items-center
      @apply h-16 w-full duration-200 relative
      @apply text-xl
      filter: grayscale(100%) opacity(0.8)
      &-active, &:hover, &:focus
        @include theme-aware("background-color", "background-primary")
        @apply outline-none
        filter: grayscale(0%) opacity(1)
      &-link-active
        @include theme-aware("border-color", "text-primary")
        @apply border-r-4
      &:focus:not(.item-active, &:hover)
        filter: opacity(0.9)
      &:hover:not(.item-caret) > .item-inner-container
        @apply transform scale-110

      & > .item-inner-container
        @apply flex items-center w-full h-full duration-200
        padding: 0 28px
        & > .text-wrapper
          @apply relative
          & > .text
            @apply font-semibold ml-4

      &-caret > .item-inner-container
        @include theme-aware("border-color", "text-primary", 0.2)
        @apply flex justify-end items-center border-t
        & >.caret
          @apply duration-200
          transition-property: transform
          &-open
            @apply transform rotate-180



.sidebar-fade-enter
  @apply opacity-0
  transform: translateX(-100%)
.sidebar-fade-enter-active
  @apply opacity-100
  transform: translateX(0)
  transition: opacity 200ms, transform 200ms
.sidebar-fade-exit
  @apply opacity-100
.sidebar-fade-exit-active
  @apply opacity-0
  transform: translateX(-100%)
  transition: opacity 200ms, transform 200ms

.sidebar-fade-2-enter
  @apply opacity-0
.sidebar-fade-2-enter-active
  @apply opacity-100
  transition: opacity 500ms
.sidebar-fade-2-exit
  @apply opacity-100
.sidebar-fade-2-exit-active
  @apply opacity-0
  ransition: opacity 500ms



