@import "./globalVars"
@import "./colorPalette"

.app
  @apply flex
  font-family: $font-primary

  & > .content-container
    @include theme-aware('background-color', 'background-secondary')
    @apply flex-1 w-full h-screen
    @apply overflow-auto

    & > .header
      @include theme-aware('background-color', 'background-primary')
      @include theme-aware('color', 'text-primary')
      @apply font-bold text-3xl text-center shadow-md
      @apply py-2 mb-4
      @screen md
        @apply py-4
      @screen lg
        @apply text-5xl
