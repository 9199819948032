// Theme-ing
$themes: (
  "light": (
    // Background
    background-primary: #ffffff,
    background-secondary: #F1F2F9,
    background-tertiary: #1976D2,
    background-active: #e3f2fd,
    // Text
    text-primary: #2B6CB0,
    text-secondary: #093360,
    text-tertiary: #ffffff,
    text-active: #448aff,
    text-reverse: #f7fafc,
    // Other
    danger: #a91e29,
    success: #008000,
    warning: #8a6f14,
    info: #0052a3,
    default: #000,
    exquisite: #8306c1,
  ),
  "dark": (
    // Background
    background-primary: #2a2e35,
    background-secondary: #12181b,
    // background-secondary: #2c2f33,
    background-tertiary: #175b78,
    background-active: #448aff,
    // Text
    text-primary: #46acff,
    text-secondary: #eeeded,
    text-tertiary: #ffffff,
    text-active: #e3f2fd,
    text-reverse: #f8f8f8,
    // Other
    danger: #fd5858,
    success: #7fff7f,
    warning: #ffb733,
    info: #33a4ff,
    default: #e8e6e3,
    exquisite: #d383fb,
  ),
);
//https://blog.prototypr.io/how-i-theme-my-react-app-with-sass-414e8f905541

/**
 * theme-aware - Change color of a css property based on the currently active theme
 *
 * @param  {key}     CSS property
 * @param  {color}   Color name defined in the themes under _color-palette.scss
 *
 * @example - @include theme-aware('background', 'color-background');
 * @returns - background: #FFF;
 */

@mixin theme-aware($key, $color, $opacity: 1) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: rgba(map-get(map-get($themes, $theme-name), $color), $opacity);
    }
  }
}